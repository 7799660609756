import { useCallback, useEffect } from 'react';

import { useQuery } from '@tanstack/react-query';
import { atom, selector, useRecoilState, useRecoilValue } from 'recoil';

import { ISanitizedCompany } from 'types/company';
import { IUserResponse, Permissions } from 'types/user';

import { fetchUser } from 'services/api/next/user';
import { getIsUserInCompany } from 'services/user';

const userState = atom<IUserResponse | null>({
  key: 'userState',
  default: null,
});

const canCreateStoriesSelector = selector<boolean | undefined>({
  key: 'canUserCreateStory',
  get: ({ get }) => {
    const user = get(userState);
    if (!user?.permissions) {
      return undefined;
    }
    return user.permissions.includes(Permissions.CREATE_STORY);
  },
});

const canEditCompanyStoriesSelector = selector<boolean | undefined>({
  key: 'canEditCompanyStories',
  get: ({ get }) => {
    const user = get(userState);
    if (!user?.permissions) {
      return undefined;
    }
    return user.permissions?.includes(Permissions.EDIT_COMPANY_STORY);
  },
});

const canEditUsersSelector = selector<boolean | undefined>({
  key: 'canEditUsers',
  get: ({ get }) => {
    const user = get(userState);
    if (!user?.permissions) {
      return undefined;
    }
    return user.permissions?.includes(Permissions.EDIT_USER);
  },
});

export const useClearUser = () => {
  const [, setUser] = useRecoilState(userState);

  const clearUser = useCallback(() => {
    setUser(null);
  }, [setUser]);

  return { clearUser };
};

export const useFetchUser = () => {
  const { data, refetch } = useQuery<IUserResponse>(['user'], fetchUser, {
    refetchOnWindowFocus: false,
    enabled: false,
  });

  return { data, refetch };
};

export const useProvideUser = () => {
  const { data, refetch } = useFetchUser();
  const [_, setUserState] = useRecoilState(userState);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data) {
      setUserState(data);
    }
  }, [data, setUserState]);
};

export const useUser = () => {
  const [user, setUser] = useRecoilState(userState);

  return { user, setUser };
};

export const useUserPermissions = (company: ISanitizedCompany) => {
  const [user] = useRecoilState(userState);

  const isUserInCompany = getIsUserInCompany(user, company.id);
  const canCreateStories = useRecoilValue(canCreateStoriesSelector);
  const canEditCompanyStories = useRecoilValue(canEditCompanyStoriesSelector);
  const canEditUsers = useRecoilValue(canEditUsersSelector);

  return {
    canCreateStories: isUserInCompany && canCreateStories,
    canEditCompanyStories,
    canEditUsers: isUserInCompany && canEditUsers,
  };
};
