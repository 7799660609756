import { IUserProfile, IUserResponse } from 'types/user';

export const getFullName = (profile: IUserProfile) => {
  const firstName = profile.firstName || '';
  const lastName = profile.lastName || '';

  if (!firstName && !lastName) {
    return 'Unknown';
  }

  return `${firstName} ${lastName}`.trim();
};

export const getInitials = (profile: IUserProfile) => {
  const letter1 = profile.firstName.split('')[0] || '';
  const letter2 = profile.lastName.split('')[0] || '';

  if (!letter1 && !letter2) {
    return 'N/A';
  }

  return `${letter1}${letter2}`.trim();
};

export const getIsUserInCompany = (
  user: null | IUserResponse,
  companyId: string,
) => {
  if (!user) {
    return undefined;
  }
  return [companyId, '*'].includes(user.profile.companyId);
};
