import { useCallback, useEffect, useState } from 'react';

import { debounce } from 'lodash';

export const useDarkMode = () => {
  const { matches } = useMediaQuery('(prefers-color-scheme: dark)');

  return { isDarkMode: matches };
};

export const useMediaQuery = (mediaQuery: string) => {
  const [matches, setMatches] = useState<boolean>();

  const handleMediaQueryChange = useCallback(
    (event: MediaQueryListEvent) => setMatches(event.matches),
    [],
  );

  useEffect(() => {
    const query = window.matchMedia(mediaQuery);
    setMatches(query.matches);
    query.addEventListener('change', handleMediaQueryChange);

    return () => query.removeEventListener('change', handleMediaQueryChange);
  }, [handleMediaQueryChange, mediaQuery]);

  return { matches };
};

export const useBreakPoint = () => {
  const [isMobile, setIsMobile] = useState(true);

  const updateIsMobile = debounce(
    useCallback(() => {
      setIsMobile(window.matchMedia('(max-width: 768px)').matches);
    }, []),
    250,
  );

  useEffect(() => {
    updateIsMobile();
    window.addEventListener('resize', updateIsMobile);

    return () => {
      window.removeEventListener('resize', updateIsMobile);
    };
  }, [updateIsMobile]);

  return { isMobile };
};
