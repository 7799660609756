import { motion } from 'framer-motion';

import { gradient, theme } from 'constants/theme';

import { useDarkMode } from 'hooks/browser/mediaQueries';
import { useNotifications } from 'hooks/notifications';

import { CloseIcon } from 'components/icons/CloseIcon';

import { useAnimation } from './Notifications.hooks';

export const Notifications = () => {
  const { notifications, removeNotification } = useNotifications();

  const { variants } = useAnimation();
  const { isDarkMode } = useDarkMode();

  return (
    <motion.div
      initial="hidden"
      animate="show"
      exit={'hidden'}
      className="fixed bottom-2 left-2 right-2 z-50 flex flex-col items-center"
    >
      {notifications.map(notification => (
        <motion.div
          key={notification.id}
          variants={variants.item}
          className={
            'text-md m-2 flex rounded-md py-3 pl-4 pr-2 text-white shadow dark:text-mineShaftGrey'
          }
          style={{ backgroundImage: gradient }}
        >
          <div className="mr-3">{notification.text}</div>

          <button onClick={() => removeNotification(notification.id)}>
            <CloseIcon
              size={24}
              color={
                isDarkMode ? theme.colors.mineShaftGrey : theme.colors.white
              }
              backgroundColor={'transparent'}
            />
          </button>
        </motion.div>
      ))}
    </motion.div>
  );
};
